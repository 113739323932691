import { ref, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import { convertISODateTime, getCurrentTimezone } from '@/@core/utils/filter'
import { apiUtil } from '@/api'
import { VIETNAM_TIME_ZONE } from '@/constants'

import useToast from '@useToast'

function getTimeInTicketTourString(string) {
  const regex = /\b([01]\d|2[0-3]):[0-5]\d\b/g
  const matches = string.match(regex)
  return matches?.[0] ?? null
}

export default function useListHandle() {
  const { toastError } = useToast()

  const filterBlank = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  }
  // Loading
  const loading = ref(true)

  const filter = ref(cloneDeep(filterBlank))
  const items = ref([])

  async function fetchItem(isClearFilter) {
    if (isClearFilter) {
      filter.value = cloneDeep(filterBlank)
    }
    try {
      loading.value = true
      const { data } = await apiUtil.getFlightCalendar(filter.value)
      items.value = data.filter(e => e.flights.length || e.tours.length || e.trains.length)
        .map(e => ({
          date: e.date,
          flights: e.flights
            .filter(flight => {
            // Lọc flights có itineraries hợp lệ
              flight.itineraries = flight.itineraries.filter(itinerary => {
                // Giữ lại các segment có departure.at bắt đầu bằng targetDate
                const filteredSegments = itinerary.filter(({ departure }) => {
                  const departureTime = moment.utc(departure.at).utcOffset(VIETNAM_TIME_ZONE)
                  const targetDate = moment.utc(e.date).utcOffset(getCurrentTimezone())
                  return departureTime.isSame(targetDate, 'day')
                })

                // Cập nhật itinerary hoặc xóa nếu không hợp lệ
                if (filteredSegments.length > 0) {
                // Nếu có segment hợp lệ, cập nhật itinerary
                  return true
                }
                // Xóa ve nếu không còn segment hợp lệ
                return false
              })
              // Giữ lại flights chỉ nếu còn itineraries hợp lệ
              return flight.itineraries.length > 0
            })
            .sort((a, b) => {
            // Lấy thời gian departure đầu tiên của mỗi flight
              const timeA = moment(a.itineraries[0]?.[0]?.departure?.at)
              const timeB = moment(b.itineraries[0]?.[0]?.departure?.at)

              if (!timeA.isValid() || !timeB.isValid()) {
                return 0 // Không thay đổi thứ tự nếu thời gian không hợp lệ
              }

              // Sắp xếp theo thời gian departure
              return timeA.diff(timeB)
            }),
          tours: e.tours
            .map(tour => ({
              ...tour,
              departureHour: getTimeInTicketTourString(tour?.price?.ticketPlaneGo),
            })),
          trains: e.trains
            .filter(train => {
            // Lọc trains có itineraries hợp lệ
              train.listTrip = (train.listTrip ?? []).filter(trip => convertISODateTime(trip.departureTime).dateFilter === e.date)
              return train.listTrip.length > 0
            })
            .sort((a, b) => {
              // Lấy thời gian departure đầu tiên của mỗi train
              const timeA = moment(`${convertISODateTime(a?.listTrip?.[0].departureTime).dateFilter}T${a?.listTrip?.[0].departureHour}:00Z`)
              const timeB = moment(`${convertISODateTime(b?.listTrip?.[0].departureTime).dateFilter}T${b?.listTrip?.[0].departureHour}:00Z`)

              if (!timeA.isValid() || !timeB.isValid()) {
                return 0 // Không thay đổi thứ tự nếu thời gian không hợp lệ
              }

              // Sắp xếp theo thời gian departure
              return timeA.diff(timeB)
            }),
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          textColor: '#000',
        }))
      loading.value = false
    } catch (error) {
      console.error({ error })
      toastError(error)
    }
  }

  watch(
    [filter],
    () => {
      fetchItem()
    },
    { deep: true },
  )

  return {
    // Loading
    loading,
    filter,
    items,
    // Functions
    fetchItem,
  }
}
